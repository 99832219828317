import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import PageWrapperStyled from './styledComponent'

class PageWrapper extends PureComponent {
  render() {
    let className = this.props.subleftBarCollapse ? ' sub-leftbar-collapse' : ''
    return (
      <PageWrapperStyled>
        <div className="sub-left-bar-container">{this.props.subLeftbar}</div>
        <div className={`page-container ${className}`}>
          <div className="page-wrapper">{this.props.children}</div>
        </div>
      </PageWrapperStyled>
    )
  }
}

PageWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
  subLeftbar: PropTypes.element.isRequired,
  subleftBarCollapse: PropTypes.bool
}

export default PageWrapper
