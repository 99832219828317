import styled from 'styled-components'

export default styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #f5f6fa;
  .sub-left-bar-container {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    .collapse {
      width: 20px;
      height: 100%;
      .expand-button {
        width: 26px;
        height: 26px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        border-radius: 100%;
        background: ${(props) => props.theme.jadeGreen};
        position: absolute;
        top: 42px;
        text-align: center;
        left: 40%;
        z-index: 1;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .dashboard-left-bar {
      &:hover {
        .collapse-button {
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
          img {
            width: 50%;
            height: 50%;
            object-fit: contain;
          }
        }
      }

      .left-bar-header {
        font-family: 'Prompt', sans-serif;
        font-weight: bold;
        padding: 35px 20px 0px 10px;
        margin-bottom: 15px;
        font-size: 32px;
      }

      .collapse-button {
        width: 26px;
        height: 26px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        border-radius: 100%;
        background: ${(props) => props.theme.jadeGreen};
        position: absolute;
        top: 42px;
        text-align: center;
        left: 94%;
        display: none;
        &:hover {
          cursor: pointer;
        }
      }

      .left-bar-sub-header {
        font-family: 'Prompt-Medium', sans-serif;
        padding: 0px 20px;
        margin-bottom: 24px;
        font-size: 14px;
        color: ${(props) => props.theme.greenFont};
      }
      .camera-list-container {
        max-height: 420px;
        overflow-y: auto;
      }
      .leftbar-line {
        margin: 0 20px;
        opacity: 0.5;
      }

      .left-bar-footer {
        margin: 30px 20px;
        display: flex;
        flex-direction: column;
        .message {
          font-family: 'Prompt', sans-serif;
          font-size: 12px;
          line-height: 1.11;
          color: white;
          margin-bottom: 20px;
        }
        .sync-container {
          font-family: 'Prompt', sans-serif;
          font-size: 12px;
          font-style: italic;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: white;
        }
        .footer-zone {
          position: absolute;
          bottom: 20px;
          margin-right: 20px;
          .footer-line {
            margin-left: 0;
            opacity: 0.5;
          }
          .footer-msg {
            font-family: 'Prompt', sans-serif;
            font-size: 12px;
            margin-top: 29px;
            line-height: 1.11;
            color: white;
            opacity: 0.5;
          }
        }
      }
    }
  }
  .page-container {
    position: absolute;
    top: 0px;
    left: 240px;
    width: calc(100% - 240px);
    height: 100%;
    overflow-x: hidden;
    .page-wrapper {
      width: 1340px;
      max-width: 1340px;
      padding: 30px 50px;
    }

    &.sub-leftbar-collapse {
      left: 180px;
      width: calc(100% - 180px);
    }
  }
  @media only screen and (min-width: 1280px) and (max-width: 1560px) {
    .page-container {
      overflow: auto;
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        background-color: ${(props) => props.theme.scrollbarBackground};
      }

      ::-webkit-scrollbar {
        height: 5px;
        background-color: ${(props) => props.theme.scrollbarBackground};
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: ${(props) => props.theme.scrollbarThumb};
      }
      &.sub-leftbar-collapse {
        left: 20px;
        width: calc(100% - 20px);
      }
    }
  }
`
